import React from 'react'
import App from 'next/app'
import Head from 'next/head'
import Router from 'next/router'
import { ThemeProvider } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import mixpanel from 'mixpanel-browser'
import theme from '../src/theme'

// Track client-side page views with Segment
Router.events.on('routeChangeComplete', url => {
  if (window.analytics) {
    window.analytics.page(url)
  }
})

export default class MyApp extends App {
  componentDidMount() {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side')
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles)
    }

    mixpanel.init(process.env.MIXPANEL_TOKEN)
  }

  render() {
    const { Component, pageProps } = this.props

    return (
      <>
        <Head>
          <title>Hire an MBA for your Startup</title>
          <meta name="viewport" content="minimum-scale=1, initial-scale=1, width=device-width" />
        </Head>
        <ThemeProvider theme={theme}>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <CssBaseline />
          <Component {...pageProps} />
        </ThemeProvider>
      </>
    )
  }
}
